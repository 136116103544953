@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  overflow-y: auto; overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./font/CabinetGrotesk-Black.otf') format('opentype');
  font-weight: 900;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Extralight.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/CabinetGrotesk-Bold.otf') format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Medium.otf') format('opentype');
  font-weight: 500;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Light.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Regular.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Extralight.otf') format('opentype');
  font-weight: 200;
}
@font-face {
  font-family: 'GeneralSans';
  src: url('./font/GeneralSans-Semibold.otf') format('opentype');
  font-weight: 100;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-label { -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: #FFF; }

.hero-section { height: 100vh; width: 100%; background-repeat: no-repeat; background-position: center; background-size: cover; margin-top: 72px; position: relative; }



.line-loop-container { overflow: hidden; width: 100%; position: relative; padding: 25px 0;}
.line-loop {  animation: scroll 3s linear infinite; white-space: nowrap;}


@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}


.time-section { position: relative; background-image: url('/public/assets/home/photo4.png'); width: 100%; background-position: center; background-size: cover; background-repeat: no-repeat; height: 709px;}
.time-box { position: absolute; background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%); background-blend-mode: multiply; height: 392px; width: 100%; bottom: 0;}

@media(max-width: 768px) {
  .time-section { height: 800px;}
  .time-box { height: auto; padding-bottom: 40px;}
}

.our-body { background-image: url('/public/assets/port/photo13.png'); height: 868px; width: 100%; background-size: cover; background-repeat: no-repeat; position: relative;}

@media(max-width: 768px) {
.our-body { background-image: unset; height: auto; padding-bottom: 0px; padding-left: 20px; padding-right: 20px;}
}

.card-overlay { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: linear-gradient(180.21deg, rgba(0, 0, 0, 0) 54.88%, rgba(0, 0, 0, 0.79) 99.82%); border-radius: 16px;}

.port-card { transition: all 0.5s;}
.port-card .port-body { display: none; transition: all 0.5s;}
.port-card:hover .port-body { display: flex;}

.we-card:before { position: absolute; content: ""; top: 0; left: 0; right: 0; bottom: 0; background: linear-gradient(180.21deg, rgba(0, 0, 0, 0) 54.88%, rgba(0, 0, 0, 0.79) 99.82%); border-radius: 16px;}
.we-card .we-body { display: none; transition: all 0.5s;}
.we-card:hover .we-body { display: flex;}